import {
  Grid,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import History from 'containers/common/history';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { accordianStyles } from 'components/accordian/accordian-styles';
import Divider from 'components/divider';
import DetailField from 'components/form/field/field';
import AccordionHeader from 'components/form/header/accordion-header';
import SubHeader from 'components/form/header/subheader';
import { LargeSpacer, SmallSpacer } from 'components/spacer/spacer';
import { compose } from 'recompose';
import { constantListLabelLookup, isValidArray } from 'services/utils/common-service';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import {
  getLanguageDisplay,
  formatPatientName,
  formatContactName,
  getPreferredEmailDisplay,
  getPreferredPhoneDisplay,
} from 'services/utils/demographic-service';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import { getAddressForDisplay } from 'services/utils/task-service';
import { getYesNoHyphenValue, getMedSyncPreferenceText } from 'services/utils/formatting';
import { useSelector } from 'react-redux';
import { EditHistory } from 'components/icons/icons';
import { convertToArborDate } from 'models/time/arbor-date';
import { getPaymentTypeLabel } from 'containers/tasks/fill-coordination/fc-util';
import { sortBy } from 'lodash';
import { contactMethodTypes, methodOfDelivery } from '../../../constants/lists';
import { EXPIRATION_DATE_FORMAT, PATIENT_RX_DELIVERY_PREFERENCES } from '../../../constants';
import { styles } from './patient-demographics-styles';
import PreferenceForm from './preference-form';
import HTTP from '../../../services/http';
import { parseContactDisplayName } from '../../../utils/name';

export function PatientPreference(props) {
  const { classes, patient, contactList } = props;
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayEditHistory, setDisplayEditHistory] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [currentWelcomeKitState, setCurrentWelcomeKitState] = useState({});
  const paymentMethodTypes = useSelector(state => state.lookups.paymentMethodTypes);
  const internalPharmacies = useSelector(state => state.pharmacies?.pharmacies);
  const selectedDeliveryPharmacy = internalPharmacies.find(
    ph => ph.id === patient.preferred_rx_delivery_pharmacy_id,
  );

  React.useEffect(() => {
    let active = true;
    (async () => {
      if (!patient?.id) {
        return;
      }
      const url = `/patients/${patient.id}/preferences/history`;
      const res = await HTTP.get(url);
      if (res && res.data.length) {
        const welcomeKitCurrentdata = res.data
          .filter(it => it.field === 'welcome_kit')
          .sort((a, b) => (new Date(a.updated) > new Date(b.updated) ? -1 : 1));
        setCurrentWelcomeKitState(() =>
          welcomeKitCurrentdata && welcomeKitCurrentdata.length
            ? welcomeKitCurrentdata[0]
            : res.data && res.data.length
            ? res.data[0]
            : {},
        );
      }
    })();
    return () => {
      active = false;
    };
  }, [patient.welcome_kit]);

  const handleEdit = e => {
    if (expanded) {
      e.stopPropagation();
    }
    setDisplayEdit(!displayEdit);
  };

  const handleEditHistory = e => {
    e.stopPropagation();
    setDisplayEditHistory(!displayEditHistory);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCancel = () => {
    setDisplayEdit(false);
  };

  let preferredContact;
  let preferredContactDisplay;

  const {
    preferred_contact_id: preferredContactId,
    preferred_rx_provider_delivery_contact_id: preferredRxProviderDeliveryContactId,
    preferred_contact_method: preferredContactMethod,
    preferred_rx_delivery_contact_id: preferredRxDeliveryContactId,
    rx_order_note: orderNote,
  } = patient;

  let preferredContactMethodDisplay = constantListLabelLookup(
    contactMethodTypes,
    preferredContactMethod,
    'Not Specified',
  );

  if (preferredContactId) {
    preferredContact = contactList[preferredContactId];
    preferredContactDisplay = formatContactName(preferredContact);
  } else {
    preferredContact = patient;
    preferredContactDisplay = formatPatientName(preferredContact);
  }

  const preferredRxDeliveryContact = preferredRxDeliveryContactId
    ? contactList[preferredRxDeliveryContactId]
    : patient;

  const preferredRxProviderDeliveryContact =
    preferredRxProviderDeliveryContactId === 0 || preferredRxProviderDeliveryContactId
      ? contactList[preferredRxProviderDeliveryContactId] ?? patient
      : null;

  const preferenceContactList = {
    0: patient,
    ...contactList,
  };
  let phonePreferredContact;

  if (preferredContactMethod === 2) {
    preferredContactMethodDisplay += ` - ${getPreferredEmailDisplay(preferredContact)}`;
  } else if (preferredContactMethod !== 4) {
    phonePreferredContact = (
      <TalkdeskPhoneNumber
        number={getPreferredPhoneDisplay(preferredContact)}
        additionalInlineText={`${preferredContactMethodDisplay} - `}
        headerText="Preferred Contact Method"
        owner={preferredContact}
      />
    );
  }

  if (!preferredContact || !preferredRxDeliveryContact) {
    return null;
  }

  const preferredRxDeliveryContactField = parseContactDisplayName(
    preferredRxDeliveryContact,
    false,
  );
  const preferredRxDeliveryProviderContactField = preferredRxProviderDeliveryContact
    ? parseContactDisplayName(preferredRxProviderDeliveryContact)
    : null;
  const preferredRxDeliveryPharmacyField = selectedDeliveryPharmacy?.name || 'None selected';

  const paymentMethodsDisplay = sortBy(patient.payment_methods, 'rank').map(paymentMethod => ({
    id: paymentMethod.id,
    rank: paymentMethod.rank,
    cardNum: `${PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.VIEW_MODE.PREFIX}${paymentMethod.card_num}`,
    expirationDate: moment(paymentMethod.expiration_date, 'MM/YYYY').format(EXPIRATION_DATE_FORMAT),
    paymentType: getPaymentTypeLabel(paymentMethod, paymentMethodTypes),
  }));

  return (
    <div>
      <LargeSpacer />
      <Accordion defaultExpanded className={classes.accordianPanel}>
        <AccordionSummary
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          onClick={handleExpand}
          expandIcon={<ExpandMoreIcon />}
        >
          {displayEdit ? (
            <AccordionHeader
              id="edit_patient_preference"
              name="Edit Patient Preference"
              editHandler={handleEdit}
              updatedDt={patient.patient_preferences_updated}
              updatedBy={patient.patient_preferences_updated_by}
            />
          ) : (
            <AccordionHeader
              id="patient_preference"
              withHistory
              name="Communication & Rx delivery preference"
              editHandler={handleEdit}
              historyHandler={handleEditHistory}
              updatedDt={patient.patient_preferences_updated}
              updatedBy={patient.patient_preferences_updated_by}
            />
          )}
          <Modal open={displayEditHistory} onClose={handleEditHistory}>
            <div style={getModalStyle()} className={classes.historyModal}>
              <History url={`/patients/${patient.id}/preferences`} subType="patient_preferences" />
            </div>
          </Modal>
        </AccordionSummary>
        <AccordionDetails>
          {displayEdit ? (
            // Render Edit Patient Preference Form
            <PreferenceForm
              preferenceContactList={preferenceContactList}
              cancelHandler={handleCancel}
              paymentMethodTypes={paymentMethodTypes}
              internalPharmacies={internalPharmacies}
            />
          ) : (
            // Render Patient Preference View
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <SubHeader name="Communication Preference" />
                  </Grid>
                </Grid>
                <Grid container spacing={7}>
                  <Grid item xs={3}>
                    <DetailField fieldName="Preferred Contact" field={preferredContactDisplay} />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Language"
                      field={getLanguageDisplay(preferredContact.languages)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {phonePreferredContact || (
                      <DetailField
                        fieldName="Preferred Contact Method"
                        field={preferredContactMethodDisplay}
                        variant="raw"
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Contact Method Note"
                      field={patient.contact_method_note}
                      variant="raw"
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <SubHeader name="Rx Delivery Preference" />
                </Grid>
                <Grid container spacing={7}>
                  <Grid item xs={6} marginBottom={4}>
                    <DetailField
                      fieldName="Preferred Rx Delivery Pharmacy"
                      field={`${preferredRxDeliveryPharmacyField}`}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={7}>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Preferred Rx Delivery Contact"
                      field={`${preferredRxDeliveryContactField}`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Preferred Rx Delivery Method"
                      field={constantListLabelLookup(
                        methodOfDelivery,
                        patient.preferred_rx_delivery_method,
                        'Not Specified',
                      )}
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      fieldName="Preferred Rx Delivery Address"
                      field={getAddressForDisplay(
                        isValidArray(preferredRxDeliveryContact.filteredAddresses) &&
                          preferredRxDeliveryContact.filteredAddresses.find(
                            entry => entry.key === patient.preferred_rx_delivery_entity_key,
                          ),
                        null,
                        preferredRxDeliveryContact.provider_office_name,
                      )}
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Rx Delivery Note"
                      field={patient.rx_delivery_note}
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField fieldName="Order Notes" field={orderNote} variant="raw" />
                  </Grid>
                  <Grid item xs={9} />
                </Grid>

                <Grid container spacing={7}>
                  <Grid item xs={6}>
                    <DetailField
                      fieldName="Preferred Provider Delivery Contact"
                      field={preferredRxDeliveryProviderContactField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      fieldName="Preferred Provider Delivery Address"
                      field={
                        preferredRxProviderDeliveryContact
                          ? getAddressForDisplay(
                              isValidArray(preferredRxProviderDeliveryContact.filteredAddresses) &&
                                preferredRxProviderDeliveryContact.filteredAddresses.find(
                                  entry =>
                                    entry.key === patient.preferred_rx_provider_delivery_entity_key,
                                ),
                              null,
                              preferredRxProviderDeliveryContact.provider_office_name,
                            )
                          : ''
                      }
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      fieldName="Rx Provider Delivery Note"
                      field={patient.rx_provider_delivery_note}
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      fieldName="Provider Order Note"
                      field={patient.rx_provider_order_note}
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={9} />
                </Grid>
                <Divider />
                <Grid container flexDirection="column">
                  {/* Patient payment methods */}
                  <Typography sx={{ fontSize: '16px', marginBottom: '16px' }} variant="h3">
                    Payment Method on File
                  </Typography>
                  <div>
                    {paymentMethodsDisplay.map(paymentMethod => (
                      <Grid container flexDirection="column" sx={{ marginBottom: '12px' }}>
                        <div>
                          <Typography
                            sx={{ fontSize: '13px', fontWeight: 600, marginBottom: '6px' }}
                            variant="h4"
                          >
                            {paymentMethod.rank
                              ? ['Primary', 'Secondary', 'Tertiary'][paymentMethod.rank - 1]
                              : null}
                          </Typography>
                          <Grid container>
                            <Grid item xs={4}>
                              <DetailField
                                fieldName={
                                  PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.VIEW_MODE.LABEL
                                }
                                variant="raw"
                                field={paymentMethod.cardNum}
                              />
                            </Grid>
                            {paymentMethod.expirationDate && (
                              <Grid item xs={2}>
                                <DetailField
                                  fieldName={
                                    PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS
                                      .EXPIRATION_DATE
                                  }
                                  variant="raw"
                                  field={paymentMethod.expirationDate}
                                />
                              </Grid>
                            )}
                            {paymentMethod.paymentType && (
                              <Grid item xs={3}>
                                <DetailField
                                  fieldName={
                                    PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS
                                      .PAYMENT_TYPE
                                  }
                                  variant="raw"
                                  field={paymentMethod.paymentType}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                    ))}
                  </div>
                </Grid>
                <Divider />
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <SubHeader name="Patient Preference - Medication Packaging" />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Easy Open Caps"
                      field={getYesNoHyphenValue(patient.easy_open_caps)}
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DetailField
                      fieldName="Adherence Packaging"
                      field={getYesNoHyphenValue(patient.adherence_packaging)}
                      variant="raw"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <SubHeader name="Patient Preference - Med Sync" />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        field={getMedSyncPreferenceText(patient.med_sync)}
                        variant="raw"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container xs={12} alignItems="flex-start">
                      <SubHeader name="Welcome Kit/Patient Educational materials received" />
                      {patient.welcome_kit === 1 && (
                        <Tooltip
                          title={`${convertToArborDate(
                            currentWelcomeKitState.updated,
                            true,
                          ).getCustomerDatetime(true)} ${
                            currentWelcomeKitState.updatedBy
                              ? `Updated by ${currentWelcomeKitState.updatedBy}`
                              : ''
                          }`}
                          placement="top-end"
                        >
                          <span className={classes.welcomeKitHistoryIcon}>
                            <EditHistory />
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs={3} data-qa-id="welcome_kit_selected_opt">
                      <DetailField field={getYesNoHyphenValue(patient.welcome_kit)} variant="raw" />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} data-qa-id="signature_required_selected_opt">
                      <SubHeader name="Patient Preference - Signature Required" />
                    </Grid>
                    <Grid item xs={3}>
                      <DetailField
                        field={getYesNoHyphenValue(patient.signature_required)}
                        variant="raw"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <SmallSpacer />
    </div>
  );
}

const combinedStyles = combineStyles(styles, accordianStyles);

export default compose(withStyles(combinedStyles))(PatientPreference);
