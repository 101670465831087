import { IPhone } from './IPhone';
import { ILanguage } from './ILanguage';
import { IAdherenceData } from './IAdherenceData';
import { IAdherenceHistoryData } from './IAdherenceHistoryData';
import { PaymentMethodResponse } from '../../containers/add-payment-method/types';

interface IPatientAddress {
  city: string; // ASHEVILLE
  key: number; // 1
  line1: string; // 12 MAIN STREET
  line2: string;
  state: string; // NC
  use: string; // Home
  zip: string; // 28801
  rank: number;
  deleted: boolean;
}

interface IPatientEmail {
  enrollment: string; // Not Specified
  key: number; // 1
  rank: number; // 1
  use: string; // Business
  value: string; // bla@bla.com
}

interface IPatientVaccination {
  created: string;
  created_by: number;
  effective_for: string;
  id: number;
  patient_id: number;
  received_dt: string;
  source: string;
  type: number;
  updated: string;
  updated_by: number;
  vaccine_id: number;
}

interface IProblemTherapy {
  id: number;
  active: boolean;
}

export interface ICdmProgramEncounter {
  id: number;
  cdm_program_id: number;
  specialty_type: ISpecialtyType;
  enrollment: ICdmEnrollmentCriteria;
  graduation: ICdmGraduationCriteria;
}

export interface ICdmEnrollmentCriteria {
  date: string;
  dc_task_id: number;
  conditions: ICdmCondition[];
}

export interface ICdmGraduationCriteria {
  date: string;
  dc_task_id: number;
  conditions: ICdmCondition[];
}

export interface ICdmCondition {
  patient_goal_id: number | null;
  clinical_data_type: ISpecialtyType;
  clinical_data_value: string;
}

export interface ISpecialtyType {
  id: number;
  name: string;
}

export interface IDiagnosis {
  code: string;
  description: string;
}

interface IPatientProblem {
  id: number;
  therapies?: IProblemTherapy[] | null;
  diagnosis: IDiagnosis;
  onset_date?: string | null;
  notes?: string | null;
  active: boolean;
  cdm_encounter?: ICdmProgramEncounter;
  clinical_support_status?: number;
  cdm_program_id: number | null;
}

export enum SmsDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export enum SmsStatus {
  Accepted = 'accepted',
  Queued = 'queued',
  Pending = 'sending',
  Sent = 'sent',
  Receiving = 'receiving',
  Received = 'received',
  Delivered = 'delivered',
  Undelivered = 'undelivered',
  Failed = 'failed', // @TODO - Interface for retry to send SMS if is in failed status
  Read = 'read',
}

export enum AdherenceComplianceStatusEnum {
  Ok = 'ok',
  NeedCheck = 'need_check',
  Checked = 'checked',
}

export interface ISmsTag {
  id: number;
  name: string;
}

export interface IPatientSmsMessage {
  id: number;
  status?: SmsStatus;
  type: SmsDirection;
  date: string;
  content: string;
  isPinned: boolean;
  display_status: string;
  display_status_additional: string | null;
  tags: ISmsTag[];
  sentBy: string | null;
  from: string | null;
  to: string | null;
  phoneNumber: string | null;
  toNumber: string | null;
  readStatusChangedByUserName: string;
}

interface IPatient {
  active: boolean; // 1
  addresses?: IPatientAddress[];
  adherence_packaging: boolean;
  adherenceData?: IAdherenceData | null;
  adherence_history_data?: IAdherenceHistoryData[];
  adherence_compliance_status: AdherenceComplianceStatusEnum | null;
  auto_secure_link: number | null;
  asl_anchor_therapy_id: number | null;
  contact_method_note?: string;
  created: string;
  created_by: number;
  customer_id: number; // 2
  dob: string; // "1966-09-06T00:00:00.000Z"
  easy_open_caps: boolean;
  emails?: IPatientEmail[];
  deceased?: number;
  deceased_dt?: string;
  filteredAddresses?: IPatientAddress[];
  filteredPhones?: IPhone[];
  first_name: string; // "Jennifer"
  gender: string; // "Female"
  id: number; // 5
  languages?: ILanguage[];
  last_name: string; // "D230"
  marital_status: string;
  middle_name: string;
  nickname: string;
  payment_methods: PaymentMethodResponse[] | undefined;
  phones?: IPhone[];
  preferred_rx_delivery_patient?: number;
  preferred_rx_delivery_contact_id: number;
  preferred_rx_delivery_provider_contact_id: number;
  preferred_contact_method?: number; // 2
  preferred_rx_delivery_method: number | null;
  preferred_rx_delivery_entity_key?: number;
  preferred_rx_provider_delivery_entity_key?: number;
  prefix: string;
  privacy_policy_sent: boolean; // 0
  problems?: IPatientProblem[];
  rx_delivery_note?: string;
  rx_provider_delivery_note?: string;
  rx_order_note?: string;
  rx_provider_order_note?: string;
  source_id: number; // 2
  source_patient_id: string; // "8010327"
  ssn: string; // "1234"
  status?: string; // "Inactive"
  suffix: string;
  updated: string;
  updated_by: string;
  vaccines: IPatientVaccination[];
  is_verified: number; // 2020-08-03T16:58:29.000Z
  verified_by: number | null; // 2020-08-03T16:58:29.000Z
  verified_dt: string | null; // 2020-08-03T16:58:29.000Z
  welcome_kit_sent: boolean; // 0
  medications_verification_status_id: number;
  medications_verification_verified_by: number;
  medications_verification_verified_dt: string;
  medications_confirmed_with_emr: number;
  medications_confirmed_with_patient: number;
  medications_confirmed_with_patient_date: string;
  allergies_verification_status_id: number;
  allergies_verification_verified_by: number;
  allergies_verification_verified_dt: string;
  allergies_confirmed_with_emr: number;
  allergies_confirmed_with_patient: number;
  allergies_confirmed_with_patient_date: string;
  problems_verified: boolean; // 0,
  problems_verified_dt: string; // 2020-08-03T16:58:29.000Z
  problems_verified_by: number;
  special_populations_verification_status_id?: number | null;
  special_populations_verification_verified_by?: number | null;
  special_populations_confirmed_with_patient?: number | null;
  special_populations_confirmed_with_patient_date?: string | null;
  special_populations_verification_verified_dt?: string | null;
  sms_messages: IPatientSmsMessage[];
  needs_reconciliation: boolean;
  need_interpreter?: boolean;
  preferred_contact_id: number;
  preferred_rx_delivery_pharmacy_id: number;
  med_sync?: number | null;
  signature_required?: number | null;
  anchor_date?: Date;
  sync_time_frame?: number | null;
  hidden_questions: { patient_id: number; question_id: number; question: string }[];
}

export type {
  IPatient,
  IPatientAddress,
  IPatientEmail,
  IPatientVaccination,
  IPatientProblem,
  IProblemTherapy,
};
